<template>
  <el-dialog :visible.sync="visible" title="批量提交" append-to-body :close-on-click-modal="false" :show-close="!doing" :close-on-press-escape="false" @closed="handleClosed" width="1120px">
    <div class="fc-e lh-150" style="margin-bottom: 20px;">
      以下为准备提交的销售单据列表，开始批量提交后无法中止，确定提交请点击“开始提交”，提交过程可能耗费较长时间，请耐心等候。
    </div>
    <el-table :data="queues" border>
      <el-table-column prop="formCode" label="销售单号" width="120" fixed />
      <el-table-column label="销售类型" width="90" :formatter="formatSaleType" v-if="$erp.enable()" show-overflow-tooltip />
      <!-- <el-table-column label="ERP单号" width="110" :formatter="v=>{ return v.formCode===v.erpFormCode?'':v.erpFormCode}" v-if="$erp.enable()" /> -->
      <el-table-column prop="contractNo" label="商场合同号" width="100" show-overflow-tooltip />
      <el-table-column prop="shopName" label="门店" min-width="240" show-overflow-tooltip />
      <el-table-column prop="customerName" label="客户名称" width="100" />
      <el-table-column prop="customerPhone" label="客户电话" width="100" show-overflow-tooltip />
      <el-table-column prop="customerAddress" label="客户收货地址" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.provinceName + scope.row.cityName + scope.row.districtName + scope.row.customerAddress }}</template>
      </el-table-column>

      <el-table-column key="progress" width="100" label="进度">
        <template slot-scope="scope">
          <div class="fc-e" v-if="scope.row._error">提交失败</div>
          <div class="fc-g" v-else-if="scope.row._cancelled">已取消</div>
          <div class="fc-wa" v-else-if="scope.row._loading">
            <i class="el-icon-loading"></i>&nbsp;提交中…
          </div>
          <div class="fc-s" v-else-if="scope.row._submited">已提交</div>
          <div class="fc-g" v-else>待提交</div>
        </template>
      </el-table-column>
    </el-table>

    <div class="h c sb" style="margin-top: 24px" v-if="!doing && finished + failed + cancelled > 0">
      <div class="h c">
        批量提交完成，其中成功
        <b class="fc-s">&nbsp;{{finished}}&nbsp;</b>个，取消
        <b class="fc-g">&nbsp;{{cancelled}}&nbsp;</b>个，失败
        <b class="fc-e">&nbsp;{{failed}}&nbsp;</b>个。
      </div>
      <el-button type="primary" v-if="!doing" @click="visible = false">关闭</el-button>
    </div>
    <div slot="footer" v-else>
      <el-button type="text" v-if="!doing" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="doing" @click="start(0)">开始提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { submit } from "@/api/shopOrder";

export default {
  data() {
    return {
      visible: false,
      queues: null,
      current: -1,
      doing: false,
      finished: 0,
      failed: 0,
      cancelled: 0,
      hasChanged: false,
    };
  },
  methods: {
    handleClosed() {
      if (this.hasChanged) {
        this.$parent.init();
      }
      this.queues = null;
      this.doing = false;
      this.finished = 0;
      this.failed = 0;
      this.cancelled = 0;
      this.hasChanged = false;
    },
    formatSaleType(row) {
      return this.$parent.formatSaleType(row);
    },
    set(orders) {
      if (orders && orders.length) {
        this.queues = orders.map((o) => {
          return Object.assign({}, o, {
            _loading: false,
            _submited: false,
            _error: false,
            _cancelled: false,
          });
        });
        this.current = -1;
        this.visible = true;
      }
    },
    start(inx) {
      if (inx >= 0 && inx < this.queues.length) {
        this.hasChanged = true;
        this.current = inx;
        this.doing = true;
        let order = this.queues[this.current];
        if (!order._loading) {
          this.doSubmit(order);
        }
      } else {
        this.$notify({
          title: "批量提交结束",
          type: "success",
          duration: 2500,
        });
        this.doing = false;
      }
    },
    doSubmit(order) {
      order._loading = true;
      submit(order.id)
        .then((res) => {
          order._submited = true;
          this.finished++;
        })
        .catch((e) => {
          order._error = true;
          this.failed++;
        })
        .finally((_) => {
          order._loading = false;
          this.start(this.current + 1);
        });
    },
  },
};
</script>